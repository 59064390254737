import React from 'react'

import style from './index.scss'

export default class Attendant extends React.Component {
  render() {
    return (
      <section id="ai_attendant">
        <section>
          <div></div>
          <section>
            <header>
              <img src={require("../../images/unisms.logo.png")} alt="合一云" />
              <p>您好 👋</p>
              <p>我们能提供什么帮助？</p>
            </header>
            <section>
              <a href="https://www.qinglite.cn/search?keywords=合一云" target="_blank">智能搜索</a>
              <div>
                手机访问
                <div>
                  <img src="/img/attendant/qr.png" alt="AI客服二维码" />
                  <p>使用微信扫一扫</p>
                </div>
              </div>
            </section>
            <footer>
              <a href="https://www.qinglite.cn/app/ai/chat/QAI2193643543f2eeaae" target="_blank">
                <label>向我们发送消息</label>
                <p>常见问题、使用帮助、人工咨询等</p>
                <img src={require("../../images/attendant/right.png")} alt="向右的箭头" />
              </a>
            </footer>
          </section>
          <footer>
            <p>
              客户服务热线:
              <a href="tel:0571-28120931"> 0571-28120931</a>
            </p>
          </footer>
        </section>
        <div><img src={require("../../images/attendant/icon.png")} alt="客户服务" /></div>
      </section>
    )
  }
}