import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { request } from '../utils/Request';
import { getStorageItem } from '../utils/Storage';
import { getQueryParams } from '../utils/URL';
import Attendant from './attendant/index';

function SitemapLinks() {
  const location = useLocation()
  const [to, setTo] = useState('')
  const [sendResult, setSendResult] = useState(null)
  const source = getStorageItem('source') || getQueryParams(location.search, 'source') || getQueryParams(location.search, 's')
  let timer = null

  const setSendResultWithAutoDisappear = (level, msg) => {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }

    setSendResult({
      level,
      msg
    })

    timer = setTimeout(() => {
      setSendResult(null)
    }, 4000);
  }

  const send = (e) => {
    e.preventDefault()

    if (!to) {
      setSendResultWithAutoDisappear('error', '请输入接收手机号')
      return
    }

    request({
      method: 'POST',
      url: '/api/testing',
      data: {
        action: 'sms.send',
        to,
        source,
        ref: 'footer'
      }
    })
      .then(res => {
        setSendResultWithAutoDisappear('success', '发送成功')
      })
      .catch(e => {
        setSendResultWithAutoDisappear('error', e.message)
      })
  }

  return (
    <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

      {/* 1st block */}
      <div className="sm:col-span-12 lg:col-span-3">
        <div>
          {/* Logo */}
          <a href="https://unisms.apistd.com/" className="inline-block" aria-label="UniSMS">
            <div className="footer-logo"></div>
            {/* <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="footer-logo">
                  <stop stopColor="#4FD1C5" offset="0%" />
                  <stop stopColor="#81E6D9" offset="25.871%" />
                  <stop stopColor="#338CF5" offset="100%" />
                </radialGradient>
              </defs>
              <rect width="32" height="32" rx="16" fill="url(#footer-logo)" fillRule="nonzero" />
            </svg> */}
          </a>
        </div>
        <div className="text-sm text-gray-600">
          {/* <Link to="#" className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">Terms</Link> · <Link to="#" className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">Privacy Policy</Link> */}
          <span className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">UniSMS</span> <span className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">(合一短信)</span>
        </div>
      </div>

      {/* 2nd block */}
      <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
        <h6 className="text-gray-800 font-medium mb-2">产品</h6>
        <ul className="text-sm">
          <li className="mb-2">
            <a href="https://unisms.apistd.com/pricing/standard" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">标准短信 (国内)</a>
          </li>
          <li className="mb-2">
            <a href="https://unisms.apistd.com/pricing/standard-intl" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">标准短信 (国际)</a>
          </li>
          <li className="mb-2">
            <a href="https://unisms.apistd.com/pricing/managed" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">托管短信</a>
          </li>
        </ul>
      </div>

      {/* 3rd block */}
      <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
        <h6 className="text-gray-800 font-medium mb-2">文档</h6>
        <ul className="text-sm">
          <li className="mb-2">
            {/* <Link to="/docs" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">介绍</Link>  */}
            <a href="https://unisms.apistd.com/docs" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">介绍</a>
          </li>
          <li className="mb-2">
            <a href="https://unisms.apistd.com/docs/tutorials" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">快速上手</a>
          </li>
          <li className="mb-2">
            <a href="https://unisms.apistd.com/docs/api-common" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">API 参考</a>
          </li>
          <li className="mb-2">
            <a href="https://unisms.apistd.com/docs/sdk-java" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">SDK 接入</a>
          </li>
        </ul>
      </div>

      {/* 4th block */}
      <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
        <h6 className="text-gray-800 font-medium mb-2">关于</h6>
        <ul className="text-sm">
          <li className="mb-2">
            <Link to="/about" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">公司简介</Link>
          </li>
          <li className="mb-2">
            <a href="/contact" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">联系我们</a>
          </li>
          <li className="mb-2">
            <a href="https://www.qinglite.cn/u/2193643543f2eeaae" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out" target="_blank">动态公告</a>
          </li>
        </ul>
      </div>

      {/* 5th block */}
      <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
        <h6 className="text-gray-800 font-medium mb-2">立即体验</h6>
        <p className="text-sm text-gray-600 mb-4">输入手机号，感受顷刻间的触达</p>
        <form>
          <div className="flex flex-wrap mb-4">
            <div className="w-full">
              <label className="block text-sm sr-only" htmlFor="phonenumber">手机号</label>
              <div className="relative flex items-center max-w-xs">
                <input id="phonenumber" type="text" value={to} onChange={e => setTo(e.target.value)} className="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm" placeholder="你的手机号" autoComplete="tel" required />
                <button type="submit" onClick={send} className="absolute inset-0 left-auto" aria-label="Subscribe">
                  <span className="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                  <svg className="w-3 h-3 fill-current text-blue-600 mx-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>
                </button>
              </div>
              {/* Success message */}
              {sendResult ? <p className={`transition mt-2 text-sm ${sendResult.level === 'success' ? 'text-green-600' : 'text-red-700'}`}>{sendResult.msg}</p> : ''}
            </div>
          </div>
        </form>
      </div>

    </div>
  )
}

function SocialLinks() {
  return (
    <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
      <li>
        <Link to="#" className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
          <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
          </svg>
        </Link>
      </li>
      <li className="ml-4">
        <Link to="#" className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Github">
          <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
          </svg>
        </Link>
      </li>
      <li className="ml-4">
        <Link to="#" className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
          <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
          </svg>
        </Link>
      </li>
    </ul>
  )
}

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
        <span className="text-gray-800 font-medium mb-2">
          <a href="https://unisms.apistd.com/pricing/standard-intl" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">热门国家国际短信 </a>
        </span>
        <span className="mb-2">
          {/* <Link to="/c/us" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">美国短信 </Link> */}
          <a href="https://unisms.apistd.com/c/us" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">美国短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/ca" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">加拿大短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/gb" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">英国短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/sg" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">新加坡短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/ph" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">菲律宾短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/th" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">泰国短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/kr" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">韩国短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/hk" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">中国香港短信 </a>
        </span>
        <span className="mb-2">
          <a href="https://unisms.apistd.com/c/nz" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">新西兰短信 </a>
        </span>
        </div>

        <SitemapLinks/>

        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">

          {/* Social links */}
          {/* <SocialLinks/> */}
          <div className="text-sm text-gray-600 flex mb-4 md:order-1 md:ml-4 md:mb-0">
            <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow noopener noreferrer">浙ICP备19021730号-3</a>
          </div>

          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">Copyright &copy; 2021-2022 UniSMS. All rights reserved. Part of the <a href="https://www.apistd.com/?s=unisms" target="_blank">Apistd</a> Family.</div>

        </div>

      </div>
      <Attendant />
    </footer>
  );
}

export default Footer;
